import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import DropdownSelect, { DropdownSize, useDropdownSelect } from '../shared/form-control/DropdownSelect';
import { Option } from '../Option';
import ClientTagService from '../../services/ClientTagService';
import { ClientTagResponse, ClientTagType } from '../../models/ClientTag';
import LanguageUtils from '../../utils/LanguageUtils';
import { useTranslation } from 'react-i18next';
import useFetchClientTags from '../../hooks/useFetchClientTags';
import { useCurrentClient, useRegistrationClientId } from '../../global-state/Clients';
import EditIcon from '../shared/icon/EditIcon';
import TrashIcon from '../shared/icon/TrashIcon';
import { mouseAndKeyboardCallbackProps } from '../../utils/ComponentUtils';
import { ModalContext } from '../../contexts/ModalContext';
import StandardModal from '../shared/modal/variants/StandardModal';
import TranslatableInput from '../shared/form-control/TranslatableInput';
import { Translations } from '../../models/Translation';
import StringUtils from '../../utils/StringUtils';
import { Item } from '../shared/form-control/DropdownDefaultComponents';

type Props = {
  onTagsChanged: (tags: ClientTagResponse[]) => void;
  label?: string;
  placeholder?: string;
  className?: string;
  selectedTagIds?: string[];
  type?: ClientTagType;
  isMulti?: boolean;
  helpText?: string;
  required?: boolean;
  error?: string;
  errorState?: boolean;
  disabled?: boolean;
  size?: DropdownSize;
  canEditTags?: boolean;
  clientId?: string;
  noOptionsText?: string;
};

const TagSelector: FC<Props> = (props) => {
  const {
    onTagsChanged,
    label,
    placeholder,
    className,
    selectedTagIds: value,
    isMulti = true,
    type = ClientTagType.ContactGroup,
    helpText,
    required,
    error,
    errorState,
    disabled,
    size,
    canEditTags,
    clientId,
    noOptionsText,
  } = props;
  const [selectedTags, setSelectedTags] = useState<Item<Translations>[]>([]);
  const [editingTag, setEditingTag] = useState<Item<Translations> | null>(null);
  const [deletingTag, setDeletingTag] = useState<Item<Translations> | null>(null);

  const { t } = useTranslation('common');

  const {
    i18n: { language },
  } = useTranslation();
  const currentClient = useCurrentClient((x) => x.value);
  const currentRegistrationClientId = useRegistrationClientId((x) => x.value);

  const { data: tags, refetch: refetchTags, isLoading: loadingTags } = useFetchClientTags([type], clientId);

  const tagOptions = useMemo<Item<Translations>[]>(() => {
    return tags?.map((x) => ({ id: x.id, text: LanguageUtils.getTranslation('name', x.translations), value: x.translations })) ?? [];
  }, [tags]);

  useEffect(() => {
    if (!value?.length || loadingTags) return;

    setSelectedTags((prev) => {
      let changed = false;

      // If `value` changes, keep `selectedTags` in sync
      value.forEach((x) => {
        if (!prev.find((o) => o.id === x)) {
          changed = true;
          return;
        }
      });
      if (!changed) return prev;

      return tagOptions.filter((x) => !!value.find((o) => o === x.id));
    });
  }, [value, tagOptions, loadingTags]);

  useEffect(() => {
    // If `tagOptions` changes, make sure we select only valid tags
    setSelectedTags((prev) =>
      prev
        .map((x) => {
          return tagOptions.find((o) => o.id === x.id);
        })
        .filter(Boolean),
    );
  }, [tagOptions]);

  const onCreateTag = useCallback(
    (value: string) => {
      ClientTagService.createTag(clientId ?? currentClient?.id ?? currentRegistrationClientId ?? '', {
        translations: { [language]: { name: value } },
        type: type,
      }).then((res) => {
        refetchTags();
        setSelectedTags((prev) => [...prev, { id: res.data.id, text: value, value: res.data.translations }]);

        onTagsChanged(
          [...selectedTags, { id: res.data.id, text: value, value: res.data.id }].map((x) => [...tags!, res.data].find((o) => o.id === x.id)!),
        );
      });
    },
    [clientId, currentClient?.id, currentRegistrationClientId, language, onTagsChanged, refetchTags, selectedTags, tags, type],
  );

  const onRemove = useCallback(
    (item: Item<Translations>) => {
      setSelectedTags((prev) => prev.filter((x) => x !== item));
      onTagsChanged(selectedTags.filter((x) => x !== item).map((x) => tags!.find((o) => o.id === x.id)!));
    },
    [onTagsChanged, selectedTags, tags],
  );

  const onChange = useCallback(
    (value: Item<Translations> | Item<Translations>[] | null) => {
      if (!value) {
        setSelectedTags([]);
        onTagsChanged([]);
        return;
      }

      const updatedTags = Array.isArray(value) ? value : [value];
      setSelectedTags(updatedTags);

      const mappedTags = updatedTags.map((x) => tags?.find((o) => o.id === x.id)).filter((tag): tag is NonNullable<typeof tag> => !!tag);
      onTagsChanged(mappedTags);
    },
    [onTagsChanged, tags],
  );

  const onEditModalClose = useCallback(() => {
    setEditingTag(null);
  }, []);

  const onTagEdit = useCallback((tag: Option<string, Translations>) => {
    setEditingTag(tag);
  }, []);

  const onTagEdited = useCallback(() => {
    if (!editingTag) return;

    ClientTagService.updateTag(currentClient!.id, editingTag.id, { translations: editingTag.value }).then((res) => {
      refetchTags();
      setEditingTag(null);
    });
  }, [currentClient, editingTag, refetchTags]);

  const onDeleteModalClose = useCallback(() => {
    setDeletingTag(null);
  }, []);

  const onTagRemove = useCallback((tag: Option<string, Translations>) => {
    setDeletingTag(tag);
  }, []);

  const onTagDeleted = useCallback(() => {
    ClientTagService.deleteTag(currentClient!.id, deletingTag!.id).then(() => {
      refetchTags();
      setDeletingTag(null);
    });
  }, [currentClient, deletingTag, refetchTags]);

  const ListRenderer = useMemo(() => {
    if (!canEditTags) return undefined;
    return EditListRenderer(onTagEdit, onTagRemove);
  }, [canEditTags, onTagEdit, onTagRemove]);

  return (
    <>
      <DropdownSelect
        className={className}
        value={selectedTags}
        onChange={onChange}
        options={tagOptions}
        allowCreation
        isMulti={isMulti}
        onCreateOption={onCreateTag}
        onRemove={onRemove}
        autoAddChars={[';']}
        label={label}
        placeholder={placeholder}
        required={required}
        error={error}
        errorState={errorState}
        disabled={disabled}
        size={size}
        customListRenderer={ListRenderer}
        customSingleValueRenderer={SingleValueRenderer}
        noOptionsText={noOptionsText}
      />

      <span className="text-dpm-14">{helpText}</span>

      <ModalContext.Provider value={{ open: !!editingTag, onClose: onEditModalClose, modalWidth: 'w-2/5' }}>
        <StandardModal
          title={t('tags.edit-tag-modal.title')}
          onCancelClick={onEditModalClose}
          confirmButtonTitle={t('modals.save')}
          onConfirmClick={onTagEdited}
        >
          {editingTag && (
            <TranslatableInput
              translations={editingTag.value}
              translationKey="name"
              onTranslationsChange={(value) => setEditingTag((prev) => prev && { ...prev, value })}
              placeholder={t('tags.edit-tag-modal.field-placeholder')}
              label={t('tags.edit-tag-modal.field-label')}
            />
          )}

          <div className="text-dpm-14 mt-8">{t('tags.edit-tag-modal.note')}</div>
        </StandardModal>
      </ModalContext.Provider>

      <ModalContext.Provider value={{ open: !!deletingTag, onClose: onDeleteModalClose, modalWidth: 'w-2/5' }}>
        <StandardModal
          title={t('tags.delete-tag-modal.title')}
          onCancelClick={onDeleteModalClose}
          confirmButtonTitle={t('tags.delete-tag-modal.confirm')}
          onConfirmClick={onTagDeleted}
        >
          {t('tags.delete-tag-modal.note')}
        </StandardModal>
      </ModalContext.Provider>
    </>
  );
};

export default TagSelector;

const EditListRenderer: (onTagEdit: (tag: Item<Translations>) => void, onTagRemove: (tag: Item<Translations>) => void) => FC<Item<Translations>> = (
  onTagEdit,
  onTagRemove,
) =>
  function EditListItem(tag) {
    const { id, text } = tag;
    const { inputValue } = useDropdownSelect();
    const isNew = useMemo(() => !!tag.__isNew__, [tag]);

    return (
      <div key={id} className="flex items-center justify-between gap-3">
        <div className="flex-shrink-0">{StringUtils.highlightText(text, inputValue)}</div>
        {!isNew && (
          <div className="flex flex-shrink-0 items-center gap-1" {...mouseAndKeyboardCallbackProps((e) => e.stopPropagation())}>
            <EditIcon onClick={() => onTagEdit(tag)} className="hover:text-accent-1 h-5 w-5" />
            <TrashIcon onClick={() => onTagRemove(tag)} className="hover:text-accent-1 h-5 w-5" />
          </div>
        )}
      </div>
    );
  };

const SingleValueRenderer: FC<Item<Translations>> = (tag) => {
  return <div>{tag.text}</div>;
};
