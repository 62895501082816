import { ApiResponse } from '../models/ApiResponse';
import {
  BulkTagRequest,
  ClientTagFilter,
  ClientTagRequest,
  ClientTagResponse,
  ClientTagUpdateRequest,
  ClientTagWithItems,
} from '../models/ClientTag';
import BaseService from './BaseService';

export default class ClientTagService extends BaseService {
  public static getAllTags(clientId: string, filters: ClientTagFilter): Promise<ApiResponse<ClientTagResponse[]>> {
    return this.get(`/v1/clients/${clientId}/tags`, { params: filters });
  }

  public static getTag<TItem = unknown>(clientId: string, tagId: string): Promise<ApiResponse<ClientTagWithItems<TItem>>> {
    return this.get(`/v1/clients/${clientId}/tags/${tagId}`);
  }

  public static updateTag(clientId: string, tagId: string, tag: ClientTagUpdateRequest): Promise<ApiResponse<ClientTagResponse>> {
    return this.put(`/v1/clients/${clientId}/tags/${tagId}`, tag);
  }

  public static deleteTag(clientId: string, tagId: string): Promise<ApiResponse<void>> {
    return this.delete(`/v1/clients/${clientId}/tags/${tagId}`);
  }

  public static createTag(clientId: string, tag: ClientTagRequest): Promise<ApiResponse<ClientTagResponse>> {
    return this.post(`/v1/clients/${clientId}/tags`, tag);
  }

  public static linkTag(clientId: string, tagId: string, targetId: string): Promise<ApiResponse<void>> {
    return this.post(`/v1/clients/${clientId}/tags/${tagId}/link/${targetId}`);
  }

  public static unlinkTag(clientId: string, tagId: string, targetId: string): Promise<ApiResponse<void>> {
    return this.delete(`/v1/clients/${clientId}/tags/${tagId}/link/${targetId}`);
  }

  public static bulkTag(clientId: string, tagId: string, payload: BulkTagRequest): Promise<ApiResponse<void>> {
    return this.post(`/v1/clients/${clientId}/tags/${tagId}/link/bulk`, payload);
  }
}
