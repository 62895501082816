import { useQuery } from '@tanstack/react-query';
import { ClientTagType } from '../models/ClientTag';
import ClientTagService from '../services/ClientTagService';
import { useCurrentClient, useRegistrationClientId } from '../global-state/Clients';
import { useInAdminSpace } from '../global-state/Workspace';
import AdminService from '../services/AdminService';

const useFetchClientTags = (types: ClientTagType[] = [ClientTagType.ContactGroup], overrideClientId?: string) => {
  const currentClient = useCurrentClient((x) => x.value);
  const registrationClientId = useRegistrationClientId((x) => x.value);
  const currentClientId = overrideClientId || currentClient?.id || registrationClientId;
  const inAdminSpace = useInAdminSpace((x) => x.value);

  return useQuery({
    queryKey: ['clientTags', currentClientId, types, inAdminSpace],
    staleTime: Infinity,
    queryFn: () => {
      if (inAdminSpace) {
        return AdminService.getClientTags(currentClientId!, { types: types, pageSize: 9999 }).then((res) => res.data);
      }

      return ClientTagService.getAllTags(currentClientId!, { types: types, pageSize: 9999 }).then((res) => res.data);
    },
    retry: false,
  });
};

export default useFetchClientTags;
