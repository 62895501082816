import { Translations } from './Translation';

export type ClientTagRequest = {
  type: ClientTagType;
  translations: Translations<'name'>;
};

export type ClientTagUpdateRequest = {
  translations: Translations<'name'>;
};

export type ClientTagFilter = {
  types?: ClientTagType[];
  pageSize?: number;
  pageNumber?: number;
  sortBy?: string;
};

export type ClientTagResponse = {
  id: string;
  type: ClientTagType;
  clientId: string;
  translations: Translations<'name'>;
  itemsCount: number;
};

export enum ClientTagType {
  ContactGroup = 0,
  UserGroup = 1,
  UserPosition = 2,
  UserDepartment = 3,
}

export type ClientTagWithItems<TItem> = ClientTagResponse & {
  items: TItem[];
};

export type BulkTagRequest = {
  selectedAll: boolean;
  excludedIds: string[];
  selectedIds: string[];
  operation: BulkTagRequestOperation;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  itemFilters: Record<string, any>;
};

export enum BulkTagRequestOperation {
  Add = 0,
  Remove = 1,
}
